/* TODO: CSS file should be updated and renamed as "staticPages" or something like that. Most of these styles are shared across a lot of static pages*/
/* also i am aware that static means downloaded from server and stateless, which is no longer the case. Take it as a simple way to define them */


.card{
  background-color: white;
  box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);
  border-radius: 1.5rem;

  width: 28rem;
  padding: 1rem 0;
  margin: 20vh auto;
  
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main {
  background-color: #ffffff;
  width: 400px;
  height: 400px;
  margin: 7em auto;
  border-radius: 1.5em;
  box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);
}

.main-pw {
  background-color: #ffffff;
  width: 400px;
  height: 60vh;
  margin: 7em auto;
  border-radius: 1.5em;
  box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);
}

.sign {
  padding-top: 40px;
  color: #f8b524;
  font-family: "Ubuntu", sans-serif;
  font-weight: bold;
  font-size: 23px;
}

/* leaving .un and .pass as separate for potential compatibility reasons with other files. Though they could be very easily simplified with just a "form input" selector*/
.un, .pass {
  width: 76%;
  color: rgb(38, 50, 56);
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  background: rgba(136, 126, 126, 0.04);
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  outline: none;
  box-sizing: border-box;
  border: 2px solid rgba(0, 0, 0, 0.02);
  margin-bottom: 50px;
  margin-left: 46px;
  text-align: center;
  margin-bottom: 27px;
  font-family: "Ubuntu", sans-serif;
}

.un:focus,
.pass:focus {
  border: 2px solid rgba(0, 0, 0, 0.18) !important;
}

.main form input:disabled,
.card form input:disabled{
  color: grey;
}

.main form button:disabled,
.card form input:disabled{
  cursor: not-allowed;
}

.please-wait-text{
  text-align: center;
}

.submit {
  cursor: pointer;
  border-radius: 5em;
  color: #fff;
  background: linear-gradient(to right, #f8a203, #f7c251);
  border: 0;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 10px;
  padding-top: 10px;
  font-family: "Ubuntu", sans-serif;
  margin-left: 35%;
  font-size: 13px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.04);
}

.submit-pw {
  cursor: pointer;
  border-radius: 5em;
  color: #fff;
  background: linear-gradient(to right, #f8a203, #f7c251);
  border: 0;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 10px;
  padding-top: 10px;
  font-family: "Ubuntu", sans-serif;
  margin-left: 27%;
  font-size: 13px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.04);
}

.forgot {
  text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
  color: #f8b524;
  padding-top: 15px;
}

.forgotpw {
  cursor: pointer;
  font-weight: 600;
  color: #f8b524;
  padding-top: 1rem;
  font-family: "Ubuntu", sans-serif;
  font-size: 10px;
  border: none;
  background-color: transparent;
  width: max-content;
}

/* a {
  text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
  color: #f8b524;
  text-decoration: none;
} */

.error {
  background-color: #f08080;
  max-width: 300px;
  margin: 0 auto;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  border-width: 0.5px;
  border-style: solid;
  border-color: #f55959;
  font-size: 12px;
}

.error-icon {
  display: inline;
  padding-right: 10px;
}

.error-message {
  display: inline;
}

@media (max-width: 600px) {
  .main {
    border-radius: 0px;
  }
}
