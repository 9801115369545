svg {
    display: block;
    margin: auto;
}
path {
    pointer-events: inherit; 
}
path:hover {
    opacity: inherit; 
    cursor: inherit; 
}